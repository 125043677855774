import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function StaffProfileTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout>
      <div className="container">
        Hello world
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
      }
    }
  }
`